import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Location } from "@reach/router"

const Header = ({ siteTitle, siteDescription }) => (
  <header className="logo">
    <Location>
      {({ location }) => {
        return location.pathname == "/" ? (
          <div>
            <Link to="/about/">
              <h1>{ siteTitle }</h1>
            </Link>
            <p>{ siteDescription }</p>
          </div>
        ) : (
          <div>
            <Link to="/">
              <h1>{ siteTitle }</h1>
            </Link>
            <p>{ siteDescription }</p>
          </div>
        )
      }}
    </Location>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string ,
}

Header.defaultProps = {
  siteTitle: ``,
  siteDescription: ``,
}

export default Header
